import React, { Fragment, memo, useEffect, useRef, useState } from 'react'

import { Link } from 'react-router'
import Icon from './Icon'
import Avatar from './Avatar'
import { UserLink } from './UserInfo'

import { format, embedUrls } from '../utils/embed'
import { i18n, timeAgo } from '../i18n'
import { Tags } from '../thread'
import { ImageViewer } from './ImageViewer'

/**
 * @callback ToggleSubscriptionCallback
 * @param { import('../../js/api').Message } message
 */

/**
 * @typedef {object} MessageProps
 * @property { import('../../js/api').Message } data data
 * @property {boolean=} isThread
 * @property {ToggleSubscriptionCallback=} onToggleSubscription
 */

/**
 * Message component
 * @param {React.PropsWithChildren<{}> & MessageProps} props props
 */
export default function Message({ data, isThread, onToggleSubscription, children }) {
    const { visitor } = window.state
    const isCode = (data.tags || []).indexOf('code') >= 0
    const likesSummary = data.likes ? `${data.likes}` : i18n('message.recommend')
    const commentsSummary = data.replies ? `${data.replies}` : i18n('message.comment')
    /**
     * @type {React.MutableRefObject<HTMLDivElement?>}
     */
    const embedRef = useRef(null)
    /**
     * @type {React.MutableRefObject<HTMLDivElement?>}
     */
    const msgRef = useRef(null)
    useEffect(() => {
        const msg = msgRef.current
        const embed = embedRef.current
        if (msg && embed) {
            embedUrls(msg.querySelectorAll('a'), embed)
            if (!embed.hasChildNodes()) {
                embed.style.display = 'none'
            }
        }
    }, [])
    const canComment = data.user && visitor && visitor.uid === data.user.uid || !data.ReadOnly && visitor && visitor.uid > 0
        || !data.ReadOnly && !isThread
    const created_at = data && new Date(`${data.timestamp}Z`)
    const isNSFW = data?.tags?.includes('NSFW')
    const [viewerVisible, setViewerVisible] = useState(false)
    return (
        <article className={`msg-cont ${isNSFW ? 'nsfw' : ''}`}>
            <ImageViewer visible={viewerVisible} attachment={data?.attachment} onClose={() => setViewerVisible(false)} />
            <header className="msg-header">
                {
                    data.user &&
                    <Avatar user={data.user}>
                        <div className="msg-ts">
                            <Link to={`/${data.user.uname}/${data.mid}`}
                                state={{ data: data }}>
                                <time
                                    dateTime={created_at}
                                    title={created_at}>
                                    {timeAgo(created_at)}
                                </time>
                            </Link>
                        </div>
                    </Avatar>
                }
            </header>
            {
                data.user && data.mid &&
                <div className="msg-txt" ref={msgRef}>
                    <Tags user={data.user} data={data.tags || []} />
                    { data.body && <MessageContainer isCode={isCode} data={{ __html: format(data.body, data.mid.toString(), isCode, false) }} /> }
                </div>
            }
            {
                data.photo && data.attachment && data.attachment.small &&
                <div className="msg-media">
                    <a href={data.attachment.url} onClick={(e) => {
                        e.preventDefault()
                        setViewerVisible(true)
                    }}>
                        <img src={data.attachment.small.url} alt="Message media" />
                    </a>
                </div>
            }
            <div className="embedContainer" ref={embedRef} />
            {canComment &&
                <nav className="l">
                    {data.user && visitor && visitor.uid === data.user.uid ? (
                        <Link to={`/${data.user.uname}/${data.mid}`} className="a-like msg-button"
                            state={{ data: data }}>
                            <span className='msg-button-icon'>
                                <Icon name="ei-heart" size="s" />
                                <span>{likesSummary}</span>
                            </span>
                        </Link>
                    ) : visitor && visitor.uid > 0 ? (
                        <Link to={`/post?body=!+%23${data.mid}`} className="a-like msg-button">
                            <span className='msg-button-icon'>
                                <Icon name="ei-heart" size="s" />
                                <span>{likesSummary}</span>
                            </span>
                        </Link>
                    ) : (
                        <a href={`https://juick.com/login?retpath=${window.location.href}`} className="a-login msg-button">
                            <span className='msg-button-icon'>
                                <Icon name="ei-heart" size="s" />
                                <span>{likesSummary}</span>
                            </span>
                        </a>
                    )}
                    {
                        data.user && canComment && ((
                            isThread ? (
                                <a className="msg-button" onClick={() => { onToggleSubscription(data) }}>
                                    {
                                        data.subscribed ? (<>
                                            <Icon name="ei-check" size="s" />
                                            <span>Subscribed</span>
                                        </>) : (<>
                                            <Icon name="ei-eye" size="s" />
                                            <span>Subscribe</span>
                                        </>)}
                                </a>
                            ) : (
                                <Link to={`/${data.user.uname}/${data.mid}`} state={{ data: data }} className="a-comment msg-button">
                                    <span className='msg-button-icon'>
                                        <Icon name="ei-comment" size="s" />
                                        <span className={`${data.unread ? 'badge' : ''}`}>{commentsSummary}</span>
                                    </span>
                                </Link>
                            )
                        ))
                    }
                </nav>
            }
            {children}
        </article>
    )
}

/**
 * @param {{isCode: boolean, data: {__html: string}}} props props
 */
function MessageContainer({ isCode, data }) {
    return isCode ? (<pre dangerouslySetInnerHTML={data} />) : (<span dangerouslySetInnerHTML={data} />)
}

/**
 *
 * @param {{forMessage: import('../api').Message}} props props
 */
function Recommends({ forMessage }) {
    const { recommendations } = forMessage
    const likes = forMessage.likes || 0
    return recommendations && recommendations.length > 0 && (
        <div className="msg-recomms">{'♡ by '}
            {
                recommendations.map((it, index) => (
                    <Fragment key={it.uri || it.uid}>
                        {index > 0 && ', '}
                        <UserLink key={it.uri || it.uid} user={it} size={'small'} />
                    </Fragment>
                ))
            }
            {
                likes > recommendations.length && (<span>&nbsp;and {likes - recommendations.length} others</span>)
            }
        </div>
    ) || null
}

const Recommendations = memo(Recommends)
