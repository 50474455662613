import Icon from './Icon'

export const ImageViewer = ({ attachment, visible, onClose }) => {
    return visible && (
        <div id="dialogt">
            <div id="dialogb" onClick={onClose}></div>
            <div id="dialogw">
                <div id="dialog_header" className="header_image">
                    <div id="dialogc" onClick={onClose}><Icon name="ei-close" size="s" /></div>
                </div>
                <a href={attachment.url}><img src={attachment.medium.url} alt="" /></a>
            </div>
        </div>
    )
}
